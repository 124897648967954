<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Category in City Content
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="CategoryCityFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Select City"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="CategoryCityForm.city_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >

                <v-select
                  id="blog-edit-category"
                  v-model="CategoryCityForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getContent(CategoryCityForm.category,CategoryCityForm.city_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Page Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="page_title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="CategoryCityForm.page_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Section  Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="CategoryCityForm.section_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >

            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>

                  <b-img
                    ref="refPreviewEl"
                    :src="CategoryCityForm.header_image !=''?`https://api.lmitac.com/${CategoryCityForm.header_image}` :Tabimage"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="CategoryCityForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_headeer_image"
                        v-model="CategoryCityForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                <QuillEditor
                  :editorRef="'whatWeDo4Editor'"
               v-model="CategoryCityForm.description"
                />
     
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import QuillEditor from '@core/components/editor/Editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    const citiesList = ref([])

    store.dispatch('cities/getAllcitites')
      .then(response => {
        citiesList.value = response.data.data
      })
      const CategoryCityForm = reactive({
      city_id: '',
      name: '',
      category: '',
      description: [],
      header_image: [],

      alt_header_image: '',
      page_title: '',
      section_title: '',
      price: '',
    })
    store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })
    const getContent = (category, city) => {
      store.dispatch('content/GetCategoryInCity', { category, city }).then(response => {
        // CategoryCityForm.related_courses=JSON.parse(response?.data.related_courses)
        CategoryCityForm.page_title = response?.data.page_title
        CategoryCityForm.section_title = response?.data.section_title
        CategoryCityForm.description = response?.data.description
        CategoryCityForm.alt_header_image = response?.data.alt_header_image
        CategoryCityForm.header_image = response?.data.header_image
      })
    }
   
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const CategoryCityFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      CategoryCityFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('city_id', CategoryCityForm.city_id)
          formData.append('category_id', CategoryCityForm.category)
          formData.append('header_image', CategoryCityForm.header_image)
          formData.append('page_title', CategoryCityForm.page_title)
          formData.append('section_title', CategoryCityForm.section_title)
          formData.append('description', CategoryCityForm.description)
          formData.append(' header_image', CategoryCityForm.header_image)

          formData.append('alt_header_image', CategoryCityForm.alt_header_image)

          console.log('formData', formData)
          store.dispatch('content/addCategoryinCity', formData)
            .then(response => {
              refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
              CategoryCityForm.city_id = ''
              CategoryCityForm.category = ''
              CategoryCityForm.header_image = ''
              CategoryCityForm.page_title = ''
              CategoryCityForm.section_title = ''
              CategoryCityForm.description = ''
              CategoryCityForm.header_image = ''

              Vue.swal({
                title: 'Category In City Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')
      console.log('token', token)
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    return {
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      CategoryCityForm,
      inputImageRenderer,
      CategoryCityFormvalidate,
      categoriesList,
      citiesList,
      getContent,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    Editor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    QuillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
