var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Course Content "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Category","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.categoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.addCourseForm.category_id)}},model:{value:(_vm.addCourseForm.category_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "category_id", $$v)},expression:"addCourseForm.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Course","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Course","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getDuration(_vm.addCourseForm.course_id)}},scopedSlots:_vm._u([(!_vm.getCourse)?{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Select Category First ... ")]}}:{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Loading ... ")]}}],null,true),model:{value:(_vm.addCourseForm.course_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "course_id", $$v)},expression:"addCourseForm.course_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Related Courses","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","multiple":"","options":_vm.RelatedCourse,"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" No data ... ")]}}],null,true),model:{value:(_vm.addCourseForm.related_courses),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "related_courses", $$v)},expression:"addCourseForm.related_courses"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'onlinewhatWeDo4Editor'},model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Objectives","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"objectives","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'objectiveswhatWeDo4Editor'},model:{value:(_vm.addCourseForm.objectives),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "objectives", $$v)},expression:"addCourseForm.objectives"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Who should attend?","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'attenderswhatWeDo4Editor'},model:{value:(_vm.addCourseForm.attenders),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "attenders", $$v)},expression:"addCourseForm.attenders"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Day","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"day","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-s-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.durationList},on:{"input":function($event){return _vm.getDayContent(_vm.addCourseForm.day)}},model:{value:(_vm.addCourseForm.day),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "day", $$v)},expression:"addCourseForm.day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Day Content\n","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Day Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'adayContentFormwhatWeDo4Editor'},model:{value:(_vm.adayContentForm),callback:function ($$v) {_vm.adayContentForm=$$v},expression:"adayContentForm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }