<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Category Page Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >

                 <QuillEditor
                  :editorRef="'whatWeDo4Editor'"
                 v-model="addCategoryContent.classic"
                />
                 
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Online Description "
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                 <QuillEditor
                  :editorRef="'onlinewhatWeDo4Editor'"
                v-model="addCategoryContent.online"
                />
                 
                              <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import QuillEditor from '@core/components/editor/Editor'
import Editor from '@tinymce/tinymce-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup(props) {
    store.dispatch('content/GetCategoryContent').then(response => {
      const data = null
      store.dispatch('content/GetCategoryContentOnline').then(res => {
        const data = {
          onlineId: res.data.data.data[0].id,
          onlineName: res.data.data.data[0].name,
          classicId: response.data.data.data[0].id,
          classicName: response.data.data.data[0].name,
          online: res.data.data.data[0].value,
          classic: response.data.data.data[0].value,
        }
        store.commit('content/CategoryContent', data)
      })
    })

    const CategoryContent = computed(() => store.state.content.CategoryContent)
    const addCategoryContent = reactive(CategoryContent)

    const addCategoryContentvalidate = ref()

    const save = () => {
      const formDataClassic = new FormData()

      formDataClassic.append('name', addCategoryContent.value.classicName)
      formDataClassic.append('value', addCategoryContent.value.classic)
      const { classicId } = addCategoryContent.value
      const formDataOnline = new FormData()
      formDataOnline.append('name', addCategoryContent.value.onlineName)
      formDataOnline.append('value', addCategoryContent.value.online)
      const { onlineId } = addCategoryContent.value

      //  addCategoryContentvalidate.value.validate().then(success => {
      //         if (success) {
      store
        .dispatch('content/UpdateCategoryContentClassic', {
          classicId,
          formDataClassic,
        })
        .then(response => {
          store
            .dispatch('content/UpdateCategoryContentOnline', {
              onlineId,
              formDataOnline,
            })
            .then(response => {
              Vue.swal({
                icon: 'success',
                title: 'Updated!',

                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        })
      // }})
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    return {
      example_image_upload_handler,
      addCategoryContent,

      addCategoryContentvalidate,

      CategoryContent,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    Editor,
    QuillEditor
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
