var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" City Content "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select City","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.citiesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getContent(_vm.addCourseForm.city_id)}},model:{value:(_vm.addCourseForm.city_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "city_id", $$v)},expression:"addCourseForm.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"City Title","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addCourseForm.title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "title", $$v)},expression:"addCourseForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Header image")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.addCourseForm.header_image !=''?("https://api.lmitac.com/" + (_vm.addCourseForm.header_image)) :_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewEl.src = _vm.Tabimage
              _vm.addCourseForm.header_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Header Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.addCourseForm.header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "header_image", $$v)},expression:"addCourseForm.header_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":" alt_header_image"},model:{value:(_vm.addCourseForm.alt_header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_header_image", $$v)},expression:"addCourseForm.alt_header_image"}})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'whatWeDo4Editor'},model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }